<template>
  <div class="mt-5">
    <h2 class="bap-title">Newsletter:</h2>
    <p>Like what you read ? Subscribe to the newsletter.</p>
    <p>I will keep you informed about the book updates.</p>
    <label class="sr-only">Email:</label>
    <b-row>
      <b-col md="6">
        <b-form @submit="onSubmit">
          <b-input-group prepend="@" class="mb-2 mr-sm-2 mb-sm-0">
            <b-form-input v-model="email" id="inline-form-input-username" placeholder="my@email.com" type="email" required name="email"></b-form-input>
            <b-button type="submit" variant="primary"><b-icon-check2-circle></b-icon-check2-circle></b-button>
          </b-input-group>
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BIconCheck2Circle } from 'bootstrap-vue'
import WebService from "@/webservice";

export default {
  name: "NewsletterInput",
  components: {BIconCheck2Circle},
  data() {
    return {
      email: "",
    }
  },
  methods: {
    onSubmit(evt) {
      evt.preventDefault()
      let req = {
        email: this.email
      }
      WebService.saveSubscriber(req).then(()=>{
        this.makeToast("Success !", "Thanks for subscribing", "success")
        this.email = ""
      }).catch(()=>{
        this.makeToast("Error", "An unexpected error has occurred, please try again later", "danger")
      })

    },
    makeToast(title, message, variant = null) {
      this.$bvToast.toast(message, {
        title: title,
        variant: variant,
        autoHideDelay: 5000,
        appendToast: true
      })
    },
  }
}
</script>

<style scoped>

</style>