<template>
  <ul v-if="biblioEntries">
    <li :id="item.citationKey" v-for="(item,idx) in biblioEntries" :key="idx">[{{item.citationKey}}] {{item.text}}</li>
  </ul>
</template>

<script>
import BIBByChapter from "@/bib/byChapter.json";

export default {
  name: "ChapterBibliography",
  props: {
    chapterId: String
  },
  created() {
    this.biblioEntries = BIBByChapter[this.chapterId]
  },
  data() {
    return {
      biblioEntries : null
    }
  },
}
</script>

<style scoped>

</style>