<template>
  <b-alert :show="true" variant="success" dismissible class="mt-2">
    Did you spot an error ? Want to give me feedback ? Here is <b-link :to="{name:routeNameFeedback}">the feedback page</b-link>!
  </b-alert>
</template>

<script>
import Consts from "@/consts";

export default {
name: "FeedbackInvite",
  data() {
    return {
      routeNameFeedback: Consts.ROUTE_NAMES.FEEDBACK,
    }
  }
}
</script>

<style scoped>

</style>