<template>
  <nav class="col-md-2 margin-top100  d-none d-md-block bg-light sidebar ">
    <div class="anchor"></div>
    <div class="sidebar-sticky">
      <ul  v-if="chapters" class="nav flex-column">
        <li v-for="(chapter,chapId) in chapters" :key="chapId" class="nav-item" >
          <!-- Chapter link-->
          <b-link  :to="{name:chapter.key}" class=" ml-1 nav-link" v-bind:class="{ active: chapter.key === chapterId }">{{ chapter.name }}</b-link>
          <!-- End Chapter link-->
          <ul v-if="chapter.key === chapterId" class="list-unstyled mt-2 ml-2">
            <li v-for="(chapItem,idx2) in headings" :key="idx2">
              <a class="chap-section-link ml-3 " :href="'#'+chapItem.id">{{ chapItem.title }}</a>
            </li>
          </ul>
        </li>
      </ul>
    </div>
  </nav>

</template>

<script>
import TOC from '@/toc/data.json'
import orderBy from "lodash/orderBy"
export default {
  name: "TOCChapter",
  props: {
    chapterId: String,

  },
  created() {
    this.chapters = orderBy(TOC, [function(o) { return o.order; }]);
    this.headings = TOC[this.chapterId].tocHeadings
  },
  data() {
    return {
      chapters: null,
      headings : Array
    }
  },
}
</script>

<style scoped>
.margin-top100{
  margin-top:60px;
}
body {
  font-size: .875rem;
}

.chap-section-link {

}
.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 48px; /* Height of navbar */
  height: calc(100vh - 48px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #6c8cb5;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

</style>