<template>
  <div>
    <!-- Title -->
    <b-row class="mt-2">
      <b-col md="1">
        <b-tooltip target="tooltip-target-1" triggers="hover">
          Table of contents
        </b-tooltip>
        <b-link id="tooltip-target-1" @click="goToTOC">
          <ListIcon style="width:40px;"></ListIcon>
        </b-link>
      </b-col>
      <b-col>
        <h1 class="chapter-title">{{ chapterTitle }}</h1>
      </b-col>

    </b-row>
    <br>
    <b-img :src="require(`@/assets/images/drawings_opti2/${imageName}`)"  :alt="imageAlt" thumbnail class="img-chapter"></b-img>
    <hr>
    <br>
  </div>
</template>

<script>
import ListIcon from "@/components/ListIcon";

export default {
  name: "ChapterHeading",
  components: {ListIcon},
  props: {
    chapterTitle: String,
    imageName: String,
    imageAlt: String,
  },
  data () {
    return {
      publicPath: process.env.BASE_URL
    }
  },
  methods: {
    goToTOC() {
      window.location.href = "/#table-of-contents"
    }
  }
}
</script>

<style scoped>

</style>