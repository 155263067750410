<template>
  <svg  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" enable-background="new 0 0 48 48">
    <g fill="#2196F3">
      <rect x="6" y="22" width="4" height="4"/>
      <rect x="6" y="14" width="4" height="4"/>
      <rect x="6" y="30" width="4" height="4"/>
      <rect x="6" y="6" width="4" height="4"/>
      <rect x="6" y="38" width="4" height="4"/>
    </g>
    <g fill="#2196F3">
      <rect x="14" y="22" width="28" height="4"/>
      <rect x="14" y="14" width="28" height="4"/>
      <rect x="14" y="30" width="28" height="4"/>
      <rect x="14" y="6" width="28" height="4"/>
      <rect x="14" y="38" width="28" height="4"/>
    </g>
  </svg>
</template>

<script>
export default {
name: "ListIcon"
}
</script>

<style scoped>

</style>